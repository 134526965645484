import React, { useState, useEffect, useContext } from 'react'
import './ListSection.scss'
import { UserContext, db } from './firebase'
import { Show, ShowDocument } from './types'

export function ListSection() {
  const user = useContext(UserContext)
  const [shows, setShows] = useState<ShowDocument[]>([])

  useEffect(() => {
    if (!user) return
    const query = db.collection('Shows').where('owner', '==', user.uid)
    query.onSnapshot((querySnapshot) => {
      const newShows: ShowDocument[] = []
      querySnapshot.forEach((doc) => {
        newShows.push({ docid: doc.id, ...doc.data() } as ShowDocument)
      })
      setShows(newShows)
    })
  }, [user])

  return (
    <section className="ListSection">
      {shows.map((showDoc) => (
        <ListSectionItem key={showDoc.docid} showDoc={showDoc} />
      ))}
    </section>
  )
}

interface ListSectionItemProps {
  showDoc: ShowDocument
}
function ListSectionItem({ showDoc }: ListSectionItemProps) {
  const [menuOpen, setMenuOpen] = useState(false)

  const onDelete = () => {
    db.collection('Shows').doc(showDoc.docid).delete()
  }

  const show = showDoc.show || ({} as Show)

  return (
    <div className="ListSectionItem">
      <div style={{ display: 'none' }}>
        {JSON.stringify(showDoc, null, '  ')}
      </div>
      <span onClick={() => setMenuOpen(!menuOpen)}>:</span>{' '}
      {menuOpen && (
        <span>
          {' '}
          <button onClick={() => onDelete()}>D</button>
        </span>
      )}
      {show.moreUrl ? <a href={show.moreUrl}>{showDoc.name}</a> : showDoc.name}
    </div>
  )
}
