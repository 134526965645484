//import firebase, { User } from "firebase";
import firebase from 'firebase/app'
import { User as $User } from 'firebase'
import 'firebase/auth'
import 'firebase/firestore'
import { createContext } from 'react'

export type User = $User

const firebaseConfig = {
  apiKey: 'AIzaSyA71twG1znOO1fAuRS8Fd3mQrSTsANaslo',
  authDomain: 'myshows-9103e.firebaseapp.com',
  databaseURL: 'https://myshows-9103e.firebaseio.com',
  projectId: 'myshows-9103e',
  storageBucket: 'myshows-9103e.appspot.com',
  messagingSenderId: '993222470700',
  appId: '1:993222470700:web:fe3b22b22cc81296ecb589',
  measurementId: 'G-HCXCRVJS2T',
}
// Initialize Firebase
firebase.initializeApp(firebaseConfig)

export const GoogleAuthProvider = firebase.auth.GoogleAuthProvider
export const auth = firebase.auth()
export const db = firebase.firestore()

export const UserContext = createContext<User | false | undefined>(undefined)
