import React, { useContext } from 'react'
import { auth, UserContext, GoogleAuthProvider } from './firebase'

import './Header.scss'

export function Header() {
  const user = useContext(UserContext)
  const onSignIn = () => auth.signInWithPopup(new GoogleAuthProvider())
  const onSignOut = () => auth.signOut()

  return (
    <header className="Header">
      {typeof user === 'object' && (
        <>
          <button onClick={onSignOut}>Sign Out</button>
          <span> Signed in as {user.displayName}</span>
          {user.photoURL && (
            <img className="avatar" src={user.photoURL} alt="Avatar" />
          )}
        </>
      )}
      {user === false && <button onClick={onSignIn}>Sign in</button>}
    </header>
  )
}
