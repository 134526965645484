import React, { useState, useEffect } from 'react'
import { User, auth, UserContext } from './firebase'

import './App.scss'
import { Header } from './Header'
import { AddBar } from './AddBar'
import { ListSection } from './ListSection'

function App() {
  const [user, setUser] = useState<User | false | undefined>(undefined)

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user === null) {
        setUser(false)
      } else {
        setUser(user)
      }
    })
  })

  return (
    <UserContext.Provider value={user}>
      <div className="App">
        <Header />
        <AddBar />
        <ListSection />
        <footer>Copyright Codeite Ltd</footer>
      </div>
    </UserContext.Provider>
  )
}

export default App
