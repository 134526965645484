import { Show } from './types'

export interface SearchResponse {
  page: number
  results: SearchResponseResult[]
  total_results: number
  total_pages: number
}

type SearchResponseResult = MovieResult | TVResult | PersonResult

interface BasicResult {
  id: number
  poster_path?: string | null
  backdrop_path: string | null
}

interface MovieResult extends BasicResult {
  media_type: 'movie'

  adult?: boolean
  overview: string
  release_date?: string
  original_title?: string
  genre_ids?: number[]
  original_language: string
  title?: string
  popularity?: number
  vote_count?: number
  video: boolean
  vote_average: number
}

interface TVResult extends BasicResult {
  media_type: 'tv'

  popularity?: number
  overview?: string
  vote_average?: number
  first_air_date?: string
  origin_country?: string[]
  genre_ids?: number[]
  original_language?: string
  vote_count?: number
  name?: string
  original_name?: string
}

interface PersonResult extends BasicResult {
  media_type: 'person'
}

export async function doSearch(query: string): Promise<Show[]> {
  const res = await fetch(
    `https://api.themoviedb.org/3/search/multi?api_key=9f75bac7576f6dc393af854d16e6ee22&language=en-US&query=${query}&page=1&include_adult=false`
  )
  if (res.ok) {
    const response = (await res.json()) as SearchResponse

    const mapped = response.results
      .map((r) => {
        const show: Show = {
          id: `${r.id || 0}`,
          name: 'no name',
        }
        if (r.poster_path) {
          show.poster = `https://image.tmdb.org/t/p/w92${r.poster_path}`
        }

        if (r.media_type === 'movie') {
          show.name = r.title || 'No title'
          show.year = r.release_date
          show.moreUrl = `https://www.themoviedb.org/movie/${r.id}`
        } else if (r.media_type === 'tv') {
          show.name = r.name || 'No name'
          show.year = r.first_air_date
          show.moreUrl = `https://www.themoviedb.org/tv/${r.id}`
        }
        return show
      })
      .filter((x) => x.id !== 'null') as Show[]

    return mapped
  }
  return []
}
