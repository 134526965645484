import React, { useState, useContext, useRef } from 'react'
import './AddBar.scss'
import { UserContext, db } from './firebase'
// import { fakeData } from './fakeData'
import { doSearch } from './theMoveDb'
import { Show } from './types'

interface SearchResult {
  id: string
  Title: string
  Year: string
  imdbID: string
  Type: string
  Poster: string
}

export function AddBar() {
  const [showName, setShowName] = useState('')
  const [searchResults, setSearchResults] = useState<Show[]>([])
  const searchTimeout = useRef<number | undefined>(undefined)
  const user = useContext(UserContext)

  const pick = async (picked: Show) => {
    await onAdd(picked.name, picked)
    setSearchResults([])
  }

  const onAdd = async (name: string, show?: Show) => {
    if (typeof user === 'object') {
      try {
        const doc: any = {
          owner: user.uid,
          name,
        }
        if (show) {
          doc.show = show
        }
        await db.collection('Shows').add(doc)
        setShowName('')
      } catch (ex) {
        console.error(ex)
      }
    }
  }

  const doLookup = (name: string) => {
    if (searchTimeout.current) {
      clearTimeout(searchTimeout.current)
    }

    if (name.length <= 1) {
      setSearchResults([])
      return
    }

    searchTimeout.current = (setTimeout(async () => {
      searchTimeout.current = undefined
      const possibleSearchResults = await doSearch(name)
      console.log('possibleSearchResults:', possibleSearchResults)
      setSearchResults(possibleSearchResults)
      // if (data) {
      //   setSearchResults(
      //     data.results.map((r: any) => {
      //       id: r.id,
      //       Title: r.name || r.title,
      //       Year: r.release_date || r.first_air_date,
      //       themoviedbId: `${r.type}/${r.id}`,
      //       Type: r.type,
      //       Poster:
      //         r.poster_path && `https://image.tmdb.org/t/p/w92${r.poster_path}`,
      //     }))
      //   )
      // } else {
      //   setSearchResults([])
      // }

      //setSearchResults(fakeData.Search)
    }, 500) as any) as number
  }

  return (
    <div className="AddBar">
      {/* The Road to El Dorado  */}
      <div className="showNameInputContainer">
        <input
          className="showNameInput"
          disabled={!user}
          value={showName}
          onChange={(e) => {
            setShowName(e.target.value)
            doLookup(e.target.value)
          }}
          onKeyDown={(e) => e.key === 'Enter' && onAdd(showName)}
        />
        <div
          className="searchResults"
          style={{
            display:
              searchResults && searchResults.length > 0 ? 'block' : 'none',
          }}
        >
          <ul className="searchResultsInner">
            {Array.isArray(searchResults) &&
              searchResults.map((data) => (
                <React.Fragment key={data.id}>
                  {data.poster && (
                    <img
                      alt="Poster"
                      onClick={() => pick(data)}
                      className="poster"
                      src={data.poster}
                    />
                  )}
                  <li className="title" onClick={() => pick(data)}>
                    <div>
                      {data.name}
                      {data.year && ` (${data.year})`}
                    </div>
                  </li>
                </React.Fragment>
              ))}
          </ul>
        </div>
      </div>
      <button disabled={showName.length < 1} onClick={() => onAdd(showName)}>
        Add
      </button>
    </div>
  )
}
